import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Toolbar, Typography } from "@mui/material";
import Search from "./Search";
import { useTranslation } from "react-i18next";
import { devices } from "../styles/theme";
import Menu from "./Menu";
import Footer from "./Footer";

const Container = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  padding: 70px;

  @media ${devices.tablet} {
    padding: 20px;
  }
  @media ${devices.mobile} {
    padding: 10px;
  }
`;

const Header = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 50p 0x;
  padding: 0;
  background-color: #fff;
  height: 160px;
  border-bottom: 1px solid #ccc;
`;

const HeaderTop = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
`;

const HeaderBottom = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
`;

const logo = require("../images/logo.png");
const LogoImage = styled("img")`
  height: 100%;
`;

const Page = ({ children, isLoading }) => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Header>
        <HeaderTop>
          <LogoImage src={logo} alt="Harpsöe Sweden" />
          {isLoading && <CircularProgress />}
          <Search />
        </HeaderTop>
        <HeaderBottom>
          <Menu />
        </HeaderBottom>
      </Header>
      {children}
      <Footer />
    </Container>
  );
};

Page.defaultProps = {
  extraMenuItems: [],
};

export default Page;
