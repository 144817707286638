import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PrivatePage from "../../components/PrivatePage";

const ProductsAdminPage = () => {
  return (
    <PrivatePage title="Produkter">
      <Typography>Här redigerar man produkter</Typography>
      <Link to="/admin/products/new">Lägg till ny produkt</Link>
    </PrivatePage>
  );
};

export default ProductsAdminPage;
