import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../services/authentication";
import { Button, Paper, styled, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "../reducers/auth";
import { authenticate } from "../reducers/auth";

const LoginWrapper = styled(Paper)`
  max-width: 400px;
  margin: 20vh auto;
  padding: 20px;
`;

const AdminLoginPage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      login(loginData)
        .unwrap()
        .then((res) => {
          dispatch(authenticate(res));
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LoginWrapper>
      <h1>Admin Login</h1>
      {isAuthenticated && (
        <>
          <p>You are already logged in.</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/admin")}
          >
            Go to admin page
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => dispatch(logout())}
          >
            Log out
          </Button>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          value={loginData.email}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          autoFocus
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={loginData.password}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? "Logging in..." : "Log in"}
        </Button>
      </form>
    </LoginWrapper>
  );
};

export default AdminLoginPage;
