import React, { useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TransitionGroup } from "react-transition-group";
import PrivatePage from "../../components/PrivatePage";
import { useGetUsersQuery } from "../../services/users";

const AdminUsersPage = () => {
  const dispatch = useDispatch();
  const { data: users, isLoading } = useGetUsersQuery();

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleClickAdd = () => {
    setShowCreateForm(true);
  };

  const handleClickToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <PrivatePage title="Användare" handleClickAdd={handleClickAdd}>
      {showCreateForm && (
        <Paper
          sx={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "50ch",
          }}
        >
          <Typography variant="h4">Skapa ny användare</Typography>
          <Typography variant="body1">
            Skapa en ny användare genom att fylla i formuläret nedan.
          </Typography>
          <FormControl variant="outlined">
            <TextField
              label="E-post"
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="password">Lösenord</InputLabel>
            <OutlinedInput
              id="password"
              label="Lösenord"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickToggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Paper>
      )}

      <Typography variant="h4">Användare</Typography>
      <List>
        {isLoading && <Typography>Laddar...</Typography>}
        {users &&
          users.map((user) => (
            <ListItem key={user.id}>
              <ListItemText primary={user.email} />
            </ListItem>
          ))}
      </List>
    </PrivatePage>
  );
};

export default AdminUsersPage;
