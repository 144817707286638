import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticate,
  selectAuthToken,
  selectServerTimeDiff,
  TOKEN_KEY,
  SERVER_TIME_DIFF_KEY,
  updateRenewalTimer,
} from "../reducers/auth";
import { useRenewTokenMutation } from "../services/tokenRenewal";

export const getTokenExpMs = (token, serverTimeDiff) => {
  const jwt = JSON.parse(atob(token.split(".")[1]));
  return jwt.exp * 1000 + serverTimeDiff;
};

export const getTokenCreatedMs = (token, serverTimeDiff) => {
  const jwt = JSON.parse(atob(token.split(".")[1]));
  return jwt.iat * 1000 + serverTimeDiff;
};

function checkIfTokenExpired(token, diff) {
  const exp = getTokenExpMs(token, diff);
  return Date.now() > exp;
}

const TokenManager = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const serverTimeDiff = useSelector(selectServerTimeDiff);

  const [renewToken] = useRenewTokenMutation();

  const [tokenRenewalTimerID, setTokenRenewalTimerID] = useState(null);
  const renewalMargin = 1000 * 60; // 1 minute

  // Token renewal
  const renewAuthToken = async () => {
    console.log("Renewing token");
    try {
      await renewToken()
        .unwrap()
        .then((res) => {
          console.log("Token renewed: ", res);
          dispatch(authenticate(res));
        });
    } catch (err) {
      console.log("Error renewing token: ", err);
      dispatch(authenticate({ token: null }));
    }
  };

  useEffect(() => {
    if (!token || !serverTimeDiff) return;

    // Clear token renewal timer if it exists
    if (tokenRenewalTimerID) {
      console.log("Clearing token renewal timer");
      clearTimeout(tokenRenewalTimerID);
      setTokenRenewalTimerID(null);
      dispatch(
        updateRenewalTimer({
          renewalTimerStart: null,
          renewalTimerLength: null,
        })
      );
    }

    // Check if token is expired
    const expired = checkIfTokenExpired(token, serverTimeDiff);
    if (expired) {
      console.log("Token expired, clearing token");
      dispatch(authenticate({ token: null }));
      return;
    }

    const now = Date.now();
    const expiration = getTokenExpMs(token, serverTimeDiff);
    const renewalTime = expiration - now - renewalMargin;

    console.log("Token not expired. Expires at", expiration);

    dispatch(
      updateRenewalTimer({
        renewalTimerStart: now,
        renewalTimerLength: renewalTime,
      })
    );

    console.log(
      "Setting token renewal timer",
      expiration,
      renewalMargin,
      renewalTime
    );

    const newTokenRenewalTimerID = setTimeout(() => {
      console.log("Token renewal timer expired, renewing token");
      renewAuthToken();
    }, renewalTime);

    setTokenRenewalTimerID(newTokenRenewalTimerID);
  }, [token, serverTimeDiff, dispatch]);

  // Load token from local storage
  useEffect(() => {
    if (token) return;

    const storedToken = localStorage.getItem(TOKEN_KEY);
    const storedServerTimeDiff = parseInt(
      localStorage.getItem(SERVER_TIME_DIFF_KEY)
    );

    if (storedToken) {
      const tokenExpired = checkIfTokenExpired(
        storedToken,
        storedServerTimeDiff
      );
      if (tokenExpired) {
        console.log("Token expired, clearing localstorage");
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SERVER_TIME_DIFF_KEY);
        return;
      }

      dispatch(
        authenticate({
          token: storedToken,
          serverTimeDiff: storedServerTimeDiff,
        })
      );
    }
  }, [token, dispatch]);
  return null;
};

export default TokenManager;
