import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import { clearMessage } from "./messages";

export const TOKEN_KEY = "authToken";
export const SERVER_TIME_DIFF_KEY = "authServerTimeDiff";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    serverTimeDiff: null,
    renewalTimerStart: null,
    renewalTimerLength: null,
  },
  reducers: {
    authenticate(state, action) {
      if (
        action.payload.token == null ||
        action.payload.token === "" ||
        action.payload.token === undefined
      ) {
        state.token = null;
        state.serverTimeDiff = null;
        state.tokenBrowserTimestamp = null;
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SERVER_TIME_DIFF_KEY);
        return;
      }
      state.token = action.payload.token;

      // If the payload doesn't have serverTimeDiff, require timestamp
      if (action.payload.serverTimeDiff == null) {
        if (action.payload.timestamp == null) {
          throw new Error(
            "reducers/auth.js authenticate: payload must have either serverTimeDiff or timestamp"
          );
        } else {
          const currentTime = Date.now();
          state.serverTimeDiff = action.payload.timestamp * 1000 - currentTime;
        }
      } else {
        state.serverTimeDiff = action.payload.serverTimeDiff;
      }
      localStorage.setItem(TOKEN_KEY, action.payload.token);
      localStorage.setItem(SERVER_TIME_DIFF_KEY, state.serverTimeDiff);

      console.log("authenticated");
    },
    updateRenewalTimer(state, action) {
      console.log("updateRenewalTimer:", action.payload);
      state.renewalTimerStart = action.payload.renewalTimerStart;
      state.renewalTimerLength = action.payload.renewalTimerLength;
    },
    logout(state) {
      state.token = null;
      state.serverTimeDiff = null;
      localStorage.removeItem(TOKEN_KEY);
    },
  },
});

export const { authenticate, logout, updateRenewalTimer } = authSlice.actions;

export const selectIsAuthenticated = (state) => state.auth.token != null;
export const selectAuthToken = (state) => state.auth.token;
export const selectServerTimeDiff = (state) => state.auth.serverTimeDiff;
export const selectRenewalTimes = (state) => ({
  renewalTimerStart: state.auth.renewalTimerStart,
  renewalTimerLength: state.auth.renewalTimerLength,
});

export const authMessagesClearingMiddleware = createListenerMiddleware();
authMessagesClearingMiddleware.startListening({
  actionCreator: authenticate,
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(clearMessage({ text: "error.unauthorized" }));
  },
});

export default authSlice.reducer;
