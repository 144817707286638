import React from "react";
import { IconButton } from "@mui/material";
import CartIcon from "@mui/icons-material/ShoppingCart";

const MenuButton = ({}) => {
  return (
    <IconButton>
      <CartIcon />
    </IconButton>
  );
};

export default MenuButton;
