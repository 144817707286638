import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const Search = () => {
  const { t, i18n } = useTranslation();
  return <TextField id="search" label={t("header.search-placeholder")} />;
};

export default Search;
