import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../utils/customBaseQuery";

export const authenticationApi = createApi({
  reducerPath: "authentication",
  baseQuery: customBaseQuery({
    baseUrl: "/api",
    credentials: "include",
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "authenticate",
        method: "POST",
        body: { email, password },
      }),
      transformResponse: (response) => ({
        token: response.data.token,
        timestamp: response.data.timestamp,
      }),
    }),
  }),
});

export const { useLoginMutation } = authenticationApi;
