import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage, setLanguage, languages } from "../reducers/language";

const LanguageMenuItem = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (languageCode) => {
    dispatch(setLanguage({ language: languageCode }));
    handleClose();
  };

  const code = useSelector(selectLanguage);

  return (
    <div>
      <Button
        id="language-menu"
        aria-controls={open ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {languages[code].icon} {languages[code].name}
      </Button>
      <Menu
        id="language-menu"
        aria-labelledby="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {Object.keys(languages).map((languageCode) => (
          <MenuItem
            key={languageCode}
            onClick={() => handlePickLanguage(languageCode)}
          >
            <ListItemIcon>{languages[languageCode].icon}</ListItemIcon>
            <ListItemText>{languages[languageCode].name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageMenuItem;
