import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1, padding: "0" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" gutterBottom>
            {t("footer.about-us")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{t("footer.about-us-text")}</ReactMarkdown>
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t("footer.contact")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{t("footer.contact-text")}</ReactMarkdown>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" gutterBottom>
            {t("footer.information-pages")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{t("footer.information-pages-text")}</ReactMarkdown>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" gutterBottom>
            {t("footer.klarna-headline")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{t("footer.klarna-banner")}</ReactMarkdown>
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t("footer.social-media")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{t("footer.social-media-text")}</ReactMarkdown>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
