import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../utils/customBaseQuery";

const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: customBaseQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ currency }) => "products?currency=" + currency,
    }),
  }),
});

export const { useGetProductsQuery } = productsApi;

export default productsApi;
