import { createSlice } from "@reduxjs/toolkit";
import productsApi from "../services/products";

const productsSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setCurrency(state, action) {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(productsApi.endpoints.getProducts.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        productsApi.endpoints.getProducts.matchFulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.items = action.payload.data;
        }
      )
      .addMatcher(
        productsApi.endpoints.getProducts.matchRejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.payload.message;
        }
      );
  },
});

export const selectAllProducts = (state) => state.products.items;
export const selectProductBySlug = (productSlug) => (state) => {
  return state.products.items.find((product) => product.slug === productSlug);
};

export const { setCurrency } = productsSlice.actions;

export default productsSlice.reducer;
