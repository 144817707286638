import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLoginForm from "./AdminLoginForm";
import {
  selectIsAuthenticated,
  logout,
  selectRenewalTimes,
} from "../reducers/auth";
import { Box } from "@mui/system";
import {
  AppBar,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const PrivatePage = ({ children, title, isLoading, handleClickAdd }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { renewalTimerStart, renewalTimerLength } =
    useSelector(selectRenewalTimes);

  const [tokenExpirationProgress, setTokenExpirationProgress] = useState(null);
  const [
    tokenExpirationProgressIntervalID,
    setTokenExpirationProgressIntervalID,
  ] = useState(null);

  const updateTokenExpirationProgress = () => {
    // Calculate how many percent of the token's lifetime has passed
    const now = Date.now();
    const progress = Math.round(
      ((Date.now() - renewalTimerStart) / renewalTimerLength) * 100
    );
    setTokenExpirationProgress(progress);
  };
  useEffect(() => {
    if (renewalTimerStart && renewalTimerLength) {
      if (tokenExpirationProgressIntervalID) {
        clearInterval(tokenExpirationProgressIntervalID);
        setTokenExpirationProgressIntervalID(null);
      }
      const intervalID = setInterval(updateTokenExpirationProgress, 1000);
      setTokenExpirationProgressIntervalID(intervalID);
      updateTokenExpirationProgress();
    } else {
      if (tokenExpirationProgressIntervalID) {
        clearInterval(tokenExpirationProgressIntervalID);
        setTokenExpirationProgressIntervalID(null);
      }
      setTokenExpirationProgress(null);
    }
  }, [renewalTimerStart, renewalTimerLength]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const adminMenuItems = [
    {
      name: "Översikt",
      path: "/admin/",
      icon: <InboxIcon />,
    },
    {
      name: "Produkter",
      path: "/admin/products",
      icon: <CategoryIcon />,
    },
    {
      name: "Användare",
      path: "/admin/users",
      icon: <PeopleIcon />,
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isAuthenticated ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" noWrap component="div">
                  {title || "Admin"}
                </Typography>
                {handleClickAdd && (
                  <IconButton onClick={handleClickAdd}>
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
              {isLoading && <CircularProgress color={"secondary"} />}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              <Toolbar>
                <Typography variant="h6" noWrap component="div">
                  Admin
                </Typography>
              </Toolbar>
              <Divider />
              <List>
                {adminMenuItems.map((item) => (
                  <ListItem key={item.name} disablePadding>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={{ textDecoration: "none" }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemAvatar>
                      <Logout />
                    </ListItemAvatar>
                    <ListItemText primary={"Logga ut"} />
                    <ListItemIcon>
                      <CircularProgress
                        color={"secondary"}
                        variant={"determinate"}
                        value={tokenExpirationProgress}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            {children}
          </Box>
        </>
      ) : (
        <AdminLoginForm />
      )}{" "}
    </Box>
  );
};

export default PrivatePage;
