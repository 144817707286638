import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const languages = {
  se: { name: "Svenska", icon: "🇸🇪" },
  en: { name: "English", icon: "🇬🇧" },
};

const strings = {
  "menu.home": { en: "Home", se: "Hem" },
  "menu.products": { en: "Products", se: "Produkter" },
  "menu.orders": { en: "Orders", se: "Ordrar" },
  "menu.contact": { en: "Contact", se: "Kontakt" },
  "menu.about-us": { en: "About us", se: "Om oss" },
  "header.search-placeholder": { en: "Search", se: "Sök" },
  "error.invalid-credentials": {
    en: "Invalid Credentials",
    se: "Felaktiga inloggningsuppgifter",
  },
  "error.invalid-token": {
    en: "Invalid Login Session",
    se: "Felaktig inloggning",
  },
  "error.no-currency-code": {
    en: "Incorrect or missing currency specified",
    se: "Felaktig eller saknad valuta angiven",
  },
  "error.server-error": { en: "Internal Error", se: "Internt fel" },
  "error.unauthorized": { en: "Unauthorized", se: "Du är inte inloggad" },
  "error.insufficient-permissions": {
    en: "Insufficient Permissions",
    se: "Otillräckliga behörigheter",
  },
  "footer.about-us": {
    en: "About us",
    se: "Om oss",
  },
  "footer.about-us-text": {
    en: 'Safety Gun Strap "SGS" is developed, manufactured, marketed and sold by Harpsöe Sweden AB',
    se: 'Safety Gun Strap "SGS" är framtagen, utvecklad, tillverkad, marknadsförs och säljs av Harpsöe Sweden AB.',
  },
  "footer.contact": {
    en: "Contact",
    se: "Kontakt",
  },
  "footer.contact-text": {
    en: "Harpsöe Sweden AB  \nHästhagsgatan 1  \n532 37 Skara\n",
    se: "Harpsöe Sweden AB  \nHästhagsgatan 1  \n532 37 Skara\n",
  },
  "footer.information-pages": {
    en: "Important Information",
    se: "Viktig information",
  },
  "footer.information-pages-text": {
    en: "* [Terms and Conditions](/terms-and-conditions/)\n* [Privacy Policy](/privacy-policy/)",
    se: "* [Villkor och bestämmelser](/terms-and-conditions/)\n* [Integritetspolicy](/privacy-policy/)",
  },
  "footer.social-media": {
    en: "Social Media",
    se: "Sociala medier",
  },
};

i18n.use(initReactI18next).init({
  resources: Object.keys(strings[Object.keys(strings)[0]]).reduce(
    (acc, lang) => ({
      ...acc,
      [lang]: {
        translation: Object.keys(strings).reduce(
          (acc, key) => ({
            ...acc,
            [key]: strings[key][lang],
          }),
          {}
        ),
      },
    }),
    {}
  ),
  lng: "se",
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };

const langSlice = createSlice({
  name: "lang",
  initialState: {
    language: "se",
  },
  reducers: {
    setLanguage(state, action) {
      const { language } = action.payload;
      state.language = language;
      i18n.changeLanguage(language);
    },
  },
});

export const { setLanguage } = langSlice.actions;
export const selectLanguage = (state) => state.lang.language;

export default langSlice.reducer;
