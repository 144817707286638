import { createApi } from "@reduxjs/toolkit/query/react";
import fetchAuthenticatedQuery from "../utils/fetchAuthenticatedQuery";

export const tokenRenewalApi = createApi({
  reducerPath: "tokenRenewalApi",
  baseQuery: fetchAuthenticatedQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    renewToken: builder.mutation({
      query: () => ({
        url: "renew_token",
        method: "POST",
      }),
      transformResponse: (response) => ({
        ...response.data,
      }),
    }),
  }),
});

export const { useRenewTokenMutation } = tokenRenewalApi;
