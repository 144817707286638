import customBaseQuery from "./customBaseQuery";

const fetchAuthenticatedQuery = (options) => {
  return customBaseQuery({
    ...options,
    prepareHeaders: (headers, { getState }) => {
      const authToken = getState().auth.token;
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  });
};

export default fetchAuthenticatedQuery;
