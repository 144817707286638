import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "../components/Page";
import ProductCard from "../components/ProductCard";
import { selectProductBySlug } from "../reducers/products";
import { useSelector } from "react-redux";
import { useGetProductsQuery } from "../services/products";
import { addMessage } from "../reducers/messages";

const ProductPage = () => {
  const { productSlug } = useParams();
  const { currency } = useSelector((state) => state.settings);
  const product = useSelector(selectProductBySlug(productSlug));
  const { error, isLoading } = useGetProductsQuery({ currency });

  useEffect(() => {
    if (error) {
      console.log(error);
      addMessage("Error fetching products", "error");
    }
  }, [error]);

  return (
    <Page isLoading={isLoading}>
      {product && (
        <>
          <h1>{product.name}</h1>
          <ProductCard product={product} />
        </>
      )}
    </Page>
  );
};

export default ProductPage;
