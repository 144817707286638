import React from "react";
import { Routes, Route } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProductListPage from "./pages/ProductListPage";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import ProductPage from "./pages/ProductPage";
import AdminOverviewPage from "./pages/Admin/AdminOverviewPage";
import TokenManager from "./system-components/TokenManager";
import Messages from "./components/Messages";
import ProductsAdminPage from "./pages/Admin/ProductsAdminPage";
import EditProductPage from "./pages/Admin/EditProductPage";
import ContactPage from "./pages/ContactPage";
import styled from "@emotion/styled";
import AdminUsersPage from "./pages/Admin/AdminUsers";

const PositionedMessages = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;
`;

function App() {
  return (
    <>
      <TokenManager />
      <ThemeProvider theme={theme}>
        <PositionedMessages>
          <Messages />
        </PositionedMessages>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<ProductListPage />} />
          <Route path="/products/:productSlug" element={<ProductPage />} />
          <Route path="/admin" element={<AdminOverviewPage />} />
          <Route path="/admin/products" element={<ProductsAdminPage />} />
          <Route
            path="/admin/products/:productSlug"
            element={<EditProductPage />}
          />
          <Route path="/admin/users" element={<AdminUsersPage />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
