import React from "react";
import Page from "../components/Page";

const HomePage = () => {
  return (
    <Page>
      <p>Home Page</p>
    </Page>
  );
};

export default HomePage;
