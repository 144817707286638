import { logout } from "../reducers/auth";
import { addMessage } from "../reducers/messages";

export const tokenMiddleware =
  ({ dispatch, _ }) =>
  (next) =>
  (action) => {
    if (
      action.type.endsWith("rejected") &&
      action.error.message === "invalid token"
    ) {
      addMessage("info", "Du har blivit utloggad på grund av inaktivitet");
      dispatch(logout());
    }
    return next(action);
  };
