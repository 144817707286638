import { createApi } from "@reduxjs/toolkit/query/react";
import fetchAuthenticatedQuery from "../utils/fetchAuthenticatedQuery";

const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchAuthenticatedQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "users",
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetUsersQuery } = usersApi;

export default usersApi;
