import React from "react";
import Card from "@mui/material/Card";
import {
  Box,
  CardContent,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

const ProductCard = ({ product, onClick }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <Card
      sx={{ maxWidth: "300px", cursor: onClick ? "pointer" : "unset" }}
      onClick={onClick}
    >
      <CardContent>
        <Box sx={{ paddingBottom: "1rem" }}>
          {imageLoaded ? (
            <img
              style={{
                width: "268px",
                height: "202px",
              }}
              src={product.image}
              alt={product.name}
              onLoad={() => setImageLoaded(true)}
            />
          ) : (
            <Skeleton variant="rectangular" width={268} height={202} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6">{product.name}</Typography>
            <Typography variant="body1">{product.price_string}</Typography>
          </Box>
          <Box>
            <IconButton>
              <Add />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
