import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import PrivatePage from "../../components/PrivatePage";

const EditProductPage = () => {
  const { productSlug } = useParams();

  return (
    <PrivatePage title={`Redigera produkten "${productSlug}"`}>
      <Typography>Här redigerar vi enskilda produkter</Typography>
    </PrivatePage>
  );
};

export default EditProductPage;
