import { Typography } from "@mui/material";
import React from "react";
import PrivatePage from "../../components/PrivatePage";

const AdminOverviewPage = () => {
  return (
    <PrivatePage title="Översikt">
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
      </Typography>
    </PrivatePage>
  );
};

export default AdminOverviewPage;
