import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../components/Page";
import ProductCard from "../components/ProductCard";
import { addMessage } from "../reducers/messages";
import { useGetProductsQuery } from "../services/products";
import { selectAllProducts } from "../reducers/products";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductListPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const products = useSelector(selectAllProducts);
  const { currency } = useSelector((state) => state.settings);
  const { error, isLoading } = useGetProductsQuery({ currency });

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          severity: "error",
          text:
            "Ett fel uppstod när produkterna skulle hämtas: " +
            t(error.data.message),
        })
      );
    }
  }, [error]);

  return (
    <Page>
      {isLoading && <p>Loading...</p>}
      {products && products.length > 0 ? (
        <ul>
          {products.map((product) => (
            <Link to={`${product.slug}`} key={product.id}>
              <ProductCard key={product.id} product={product} />
            </Link>
          ))}
        </ul>
      ) : (
        <p>Inga produkter hittades</p>
      )}
    </Page>
  );
};

export default ProductListPage;
