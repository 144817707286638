import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import messages from "./reducers/messages";
import settings from "./reducers/settings";
import productsApi from "./services/products";
import usersApi from "./services/users";
import productsReducer from "./reducers/products";
import authReducer, { authMessagesClearingMiddleware } from "./reducers/auth";
import langReducer from "./reducers/language";
import { authenticationApi } from "./services/authentication";
import { tokenRenewalApi } from "./services/tokenRenewal";
import { tokenMiddleware } from "./middleware/tokenMiddleware";

const store = configureStore({
  reducer: {
    [productsApi.reducerPath]: productsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [tokenRenewalApi.reducerPath]: tokenRenewalApi.reducer,
    products: productsReducer,
    settings: settings,
    messages: messages,
    auth: authReducer,
    lang: langReducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authMessagesClearingMiddleware.middleware)
      .concat(thunk)
      .concat(tokenMiddleware)
      .concat(authenticationApi.middleware)
      .concat(tokenRenewalApi.middleware)
      .concat(productsApi.middleware)
      .concat(usersApi.middleware),
});

export default store;
