import { createTheme } from "@mui/material";

export const sizes = {
  mobile: "600px",
  tablet: "768px",
  desktop: "1024px",
  large: "1440px",
};

export const devices = {
  mobile: `(max-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(max-width: ${sizes.desktop})`,
  large: `(max-width: ${sizes.large})`,
};

export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#caa262",
    },
    secondary: {
      main: "#f50057",
    },
  },
};

export default createTheme(themeOptions);
