import { Alert, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, selectMessages } from "../reducers/messages";

const Message = ({ message }) => {
  const dispatch = useDispatch();
  const handleClearMessage = () => {
    dispatch(clearMessage({ key: message.key }));
  };
  const [open, setOpen] = React.useState(true);

  return (
    <Box>
      <Collapse in={open} onExited={handleClearMessage}>
        <Alert severity={message.severity} onClose={() => setOpen(false)}>
          {message.text}
        </Alert>
      </Collapse>
    </Box>
  );
};

const Messages = () => {
  const messages = useSelector(selectMessages);

  return (
    messages &&
    messages.map((message) => <Message key={message.key} message={message} />)
  );
};

export default Messages;
