import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addMessage = createAsyncThunk(
  "messages/addMessage",
  (payload, { dispatch }) => {
    console.log("addMessage", payload);

    const { text, severity } = payload;
    const key = Date.now();
    const message = {
      text,
      severity,
      count: 1,
      key,
    };

    setTimeout(() => {
      dispatch(clearMessage({ key: message.key }));
    }, 10000);

    return message;
  }
);

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
  },
  reducers: {
    clearMessage(state, action) {
      const { key, text, severity } = action.payload;
      state.messages = state.messages.filter((message) => {
        if (key) {
          return message.key !== key;
        } else if (text && severity) {
          return message.text !== text && message.severity !== severity;
        } else if (text) {
          return message.text !== text;
        } else if (severity) {
          return message.severity !== severity;
        } else {
          return false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addMessage.fulfilled, (state, action) => {
      const message = action.payload;
      const existingMessage = state.messages.find(
        (m) => m.text === message.text && m.type === message.type
      );

      if (existingMessage) {
        existingMessage.count += 1;
        return;
      }

      state.messages.push(message);
    });
  },
});

export const { clearMessage } = messagesSlice.actions;

export const selectMessages = (state) => state.messages.messages;

export default messagesSlice.reducer;
