// utils/fetchAuthenticatedQuery.js

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { addMessage } from "../reducers/messages";
import { t } from "i18next";
import { authenticate } from "../reducers/auth";

const customBaseQuery = (baseQueryArg) => {
  const baseQuery = fetchBaseQuery(baseQueryArg);

  return async (args, api, extra) => {
    const result = await baseQuery(args, api, extra);

    if (result.error) {
      if (result.error.status === 401) {
        api.dispatch(
          authenticate({
            token: null,
          })
        );
      }

      if (result.error.data && result.error.data.message) {
        api.dispatch(
          addMessage({
            severity: "error",
            text: result.error.data.message,
          })
        );
      }
    } else if (result.data && result.data.message) {
      api.dispatch(
        addMessage({
          severity: "info",
          text: result.data.message,
        })
      );
    }

    return result;
  };
};

export default customBaseQuery;
