import React, { useState } from "react";
import { styled, Menu as MaterialMenu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { devices } from "../styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import CartMenuButton from "./ShoppingCart";
import LanguageMenuItem from "./Language";

const MenuContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuLeft = styled("div")`
  display: flex;
  justify-content: space-after;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  gap: 20px;
`;

const MenuRight = styled("div")`
  display: flex;
  justify-content: space-before;
  align-items: center;
  height: 100%;
`;

const Mobile = styled("div")`
  display: none;
  @media ${devices.mobile} {
    display: block;
  }
`;

const MenuItems = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuItem onClick={handleClose} component={Link} to="/">
        {t("menu.home")}
      </MenuItem>
      <MenuItem onClick={handleClose} component={Link} to="/products">
        {t("menu.products")}
      </MenuItem>
      <MenuItem onClick={handleClose} component={Link} to="/contact">
        {t("menu.contact")}
      </MenuItem>
      <MenuItem onClick={handleClose} component={Link} to="/about">
        {t("menu.about-us")}
      </MenuItem>
    </>
  );
};

const Menu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuContainer>
      <MenuLeft>
        <Button
          aria-controls={open ? "main-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {open ? <MenuOpenIcon /> : <MenuIcon />}
        </Button>

        <MaterialMenu
          id="main-menu"
          aria-labelledby="main-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItems handleClose={handleClose} />
        </MaterialMenu>
        <LanguageMenuItem />
      </MenuLeft>
      <MenuRight>
        <CartMenuButton />
      </MenuRight>
    </MenuContainer>
  );
};

export default Menu;
